import { Component } from '@angular/core';

@Component({
  selector: 'guarda-digital-loader',
  templateUrl: './guarda-digital-loader.component.html',
  styleUrls: ['./guarda-digital-loader.component.scss']
})
export class GuardaDigitalLoaderComponent {

}

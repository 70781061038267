import { ModalService } from './../services/modal.service';
import { LoggingService } from './../assets/config/logging.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_BR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { GuardaDigitalLoaderModule } from './components/guarda-digital-loader/guarda-digital-loader.module';

registerLocaleData(pt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GuardaDigitalLoaderModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: pt_BR },
    LoggingService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuardaDigitalLoaderComponent } from './guarda-digital-loader.component';



@NgModule({
  declarations: [GuardaDigitalLoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [
    GuardaDigitalLoaderComponent
  ]
})

export class GuardaDigitalLoaderModule { }

import { LoggingService } from './../assets/config/logging.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GuardaDigitalLoaderService } from './components/guarda-digital-loader/guarda-digital-loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  isLoading: boolean = false;

  constructor(private loaderService: GuardaDigitalLoaderService) { }

  ngOnInit(): void {
    this.loaderService.loading.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }
}

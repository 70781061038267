export const environments = {
  production: false,
  api: 'https://api-dev.guardadigital.com.br',
  viacepApi: 'https://viacep.com.br/ws/',
  COOKIE_EXPIRE_TIME: 99999,
  COOKIE_PATH: '/',
  redirectUri: 'https://app-dev.guardadigital.com.br',
  securityKey: '9Ta1YMPfYAgqlQUzN/qon02rNqT0SG/0',
  instrumentationKey: 'ffb971d4-7471-4756-b997-8323e34455f8',
  efiCredentials: {
    ambiente: 'sandbox',
    client_id: 'Client_Id_b0dfb4ef385bf06b77cbfe7ab7ce6133aa2dbf2d',
    client_secret: 'Client_Secret_24a7259450b9cf795ed0a1e51c695e23153e31b2',
    accountId: '56afc72272e8fcc3f474c04c3e4565b4',
    url: 'https://cobrancas-h.api.efipay.com.br'
  }
}
